import theme from "@app/theme/dark";
import CoatOfArmsOfRF from "@assets/Coat_of_Arms_of_RF.svg";
import DynamicsHighFO from "@assets/svg_components/DynamicsHighFO";
import DynamicsHighRF from "@assets/svg_components/DynamicsHighRF";
import DynamicsLowFO from "@assets/svg_components/DynamicsLowFO";
import DynamicsLowRF from "@assets/svg_components/DynamicsLowRF";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useAppSelector } from "@store/redux/hooks";
import { MODEL_IDS, MODELS } from "@store/scheme/olap";
import { TAB_ID } from "@store/scheme/region";
import { Feature } from "geojson";
import { createElement, useCallback, useMemo, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import { CustomScrollbar } from "@/shared/CustomScrollbar/ui/CustomScrollbar";
import { WrapperScrollbar } from "@/shared/CustomScrollbar/ui/CustomScrollbar.styled";
import { FoCoatsOfArms } from "@/shared/FoCoatsOfArms/ui/FoCoatsOfArms";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { REGION_COATS, REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { formatNumber } from "@/shared/lib/heplers";
import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { useData } from "@/shared/Table/hooks/useTotal";
import { useTransitionPage } from "@/shared/Table/hooks/useTransitionPage";
import { IconButtonStyled } from "@/shared/TopBar/ui/TopBar.styled";
import { ListTextStyled } from "@/widgets/AppBar/ui/AppBarFilter/VDLFilter.styled";

import { VDLMainT } from "../../lib/types";
import {
  ButtonStyled,
  Icon,
  RegionsItem,
  RegionsItemFact,
  RegionsItemNumber,
  RegionsTitle,
  RegionTitleSubject,
  Text,
  ValueColorRiskVLD,
  ValueDate,
  ValueVLD,
  Wrapper,
  WrapperContent,
  WrapperStack,
} from "./VDLMain.styled";

export const VDLMain = ({ id, selectedTabId, data, value }: VDLMainT) => {
  const showRef = useRef<HTMLDivElement | null>(null);
  const { move, handleMove, handleChange } = useOpenIncident();
  const { isCheckFuturePlan } = useFuturePlan();
  const { resetSubjectId, transitionTab } = useTransitionPage();
  const modelKPI = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const { enabled: achEnabled } = useAppSelector(
    (state) => state.controls.achievement
  );
  const { compare } = useAppSelector((state) => state.controls);
  const { currentPeriod } = useAppSelector((state) => state.controls.period);
  const { foData, regionsData, kpiData } = useAppSelector(
    (state) => state.map.geo
  );

  const { districtId } = useParams<"districtId">();
  const navigate = useNavigate();
  const { getIndicator } = useData();
  const { regions, districts } = useRegions();
  const { text, date, projects, color } = data ?? {};

  const iconsPath = REGION_COATS[id as keyof typeof REGION_COATS];

  const selectedRegion = regions.find(
    (r) => REGION_COUNTRY_CODE !== r.id && r.id === String(id)
  );
  const { pathname } = useLocation();

  const highIcon =
    compare.value === COMPARE_TYPE.FO ? DynamicsHighFO : DynamicsHighRF;
  const lowIcon =
    compare.value === COMPARE_TYPE.FO ? DynamicsLowFO : DynamicsLowRF;

  const regionsIsSelect = useMemo(() => {
    return !districtId
      ? !!regions?.find(({ id: regionId }) => regionId === id)
      : id === districtId;
  }, [districtId, id, regions]);

  const achievementEnabled = useMemo(() => {
    return regionsIsSelect && achEnabled && data?.id === "vdlProjects";
  }, [achEnabled, data?.id, regionsIsSelect]);

  const navigateParams = useMemo(() => {
    return selectedRegion?.parentId[0]
      ? {
          districtId: selectedRegion?.parentId[0],
          regionId: id,
        }
      : {
          districtId: id,
          regionId: null,
        };
  }, [selectedRegion, id]);

  const handleClick = () => {
    resetSubjectId();
    let path = "/achievementLevel";

    switch (true) {
      case [TAB_ID.VDL_PROJECTS].includes(selectedTabId):
        path = `${path}/${TAB_ID.VDL_PROJECTS}`;
        break;
      case [
        TAB_ID.VDL,
        TAB_ID.MANUFACTURING,
        TAB_ID.PROFIT,
        TAB_ID.INVESTMENT,
        TAB_ID.EMPLOYED,
      ].includes(selectedTabId):
        path = `${path}/${TAB_ID.VDL}`;
        break;

      case [
        TAB_ID.PROJECTS,
        TAB_ID.RESULT_CHECKPOINTS,
        TAB_ID.RESULT_PROJECTS,
      ].includes(selectedTabId):
        path = `${path}/${TAB_ID.PROJECTS}`;
        break;
      default:
        break;
    }

    navigate(path, {
      state: { ...navigateParams, tabId: selectedTabId, pathname },
    });
  };

  const currentRegions = useMemo(() => {
    return (
      districtId
        ? regionsData?.jsonData?.features.filter(
            ({ properties }: Feature) => properties?.district_id === districtId
          )
        : foData?.jsonData?.features
    )
      ?.map((region) => {
        const vdlPModel = getIndicator(kpiData, region?.properties?.id, "123");
        const { factChangeAppg, factChangeYearStart, colorGraph } =
          modelKPI.indexes;
        const fact = vdlPModel?.values?.[factChangeAppg];
        const color = vdlPModel?.values?.[colorGraph];

        return {
          isLowRating: fact ? Math.round(fact) < 90 : undefined,
          sortValue: fact || 0,
          name: region?.properties?.name,
          id: region?.properties?.id,
          position: vdlPModel?.values?.[factChangeYearStart],
          color,
          fact:
            // костыль, чтобы не отображать тире
            formatNumber(fact, vdlPModel?.attributeVals?.UNIT, 0) === "-"
              ? ""
              : formatNumber(fact, vdlPModel?.attributeVals?.UNIT, 0),
        };
      })
      ?.sort(
        (a, b) =>
          b?.sortValue - a?.sortValue ||
          a?.name?.[0]
            ?.toLowerCase()
            ?.localeCompare(b?.name?.[0]?.toLowerCase())
      );
  }, [
    kpiData,
    modelKPI,
    districtId,
    foData?.jsonData?.features,
    getIndicator,
    regionsData?.jsonData?.features,
  ]);

  const getSubjectName = (): string => {
    if (id === REGION_COUNTRY_CODE) return "Российская Федерация";
    if (selectedRegion)
      return selectedRegion.attributeVals.name.replace(
        "федеральный округ",
        "ФО"
      );
    else
      return districts.find((item) => id === item.id)?.attributeVals.name || "";
  };

  const handleNavigateToSubject = useCallback(() => {
    if (selectedTabId !== TAB_ID.RATE) {
      let tabName = transitionTab(selectedTabId);
      navigate(
        `/region/${selectedRegion?.id || REGION_COUNTRY_CODE}/${tabName}`
      );
    }
  }, [selectedRegion, selectedTabId]);

  const [positionRight, style, title] = useMemo(() => {
    const positionRight = achievementEnabled
      ? !isCheckFuturePlan
        ? 15
        : 50
      : 8;
    const style = !achievementEnabled ? { top: "0.5rem" } : {};
    const title = !achievementEnabled
      ? `${
          achEnabled &&
          ![
            TAB_ID.EMPLOYED,
            TAB_ID.INVESTMENT,
            TAB_ID.MANUFACTURING,
            TAB_ID.PROFIT,
          ].includes(selectedTabId)
            ? "Достижение "
            : ""
        }${text || "Нет данных"}`
      : "Рейтинг";
    return [positionRight, style, title];
  }, [selectedTabId, achievementEnabled, achEnabled, isCheckFuturePlan, text]);

  return (
    <Wrapper
      ref={showRef}
      onMouseEnter={() => handleMove(true)}
      onMouseLeave={() => handleMove(false)}
    >
      <WrapperStack
        direction={achievementEnabled ? "row" : "column"}
        gap={achievementEnabled ? 2 : 4}
      >
        {selectedRegion?.level === 1 ? (
          <FoCoatsOfArms
            idFo={id}
            achEnabled={achievementEnabled}
            isVDL={true}
          />
        ) : (
          <Icon
            achEnabled={achievementEnabled}
            src={selectedRegion ? iconsPath : CoatOfArmsOfRF}
            alt="Coat of Arms"
          />
        )}
        <RegionTitleSubject>
          <ListTextStyled secondary={getSubjectName()} />

          {move && (
            <IncidentCreateButton
              posRight={positionRight}
              style={{ ...style }}
              onClick={(event) => {
                handleChange(event, showRef?.current, title, date);
              }}
            />
          )}

          {(currentPeriod >= "320220004" ||
            selectedRegion?.level === 2 ||
            currentPeriod === "0") && (
            <div style={{ marginLeft: "auto" }}>
              <IconButtonStyled accent onClick={handleNavigateToSubject}>
                <ArrowForwardIcon />
              </IconButtonStyled>
            </div>
          )}
        </RegionTitleSubject>
      </WrapperStack>

      <WrapperContent>
        {!achievementEnabled && (
          <>
            <Text>{`${
              achEnabled &&
              ![
                TAB_ID.EMPLOYED,
                TAB_ID.INVESTMENT,
                TAB_ID.MANUFACTURING,
                TAB_ID.PROFIT,
              ].includes(selectedTabId)
                ? "Достижение "
                : ""
            }${text || "Нет данных"}`}</Text>

            {data?.id !== "risks" && (
              <>
                {!achEnabled &&
                  (!regionsIsSelect || districtId) &&
                  createElement(
                    Number(
                      data?.[
                        compare.value === COMPARE_TYPE.FO ? "hlFO" : "hlRF"
                      ]
                    ) < 0
                      ? lowIcon
                      : highIcon,
                    {
                      style: {
                        height: 40,
                        width: 40,
                        marginRight: theme.spacing(1),
                      },
                    }
                  )}

                <ValueVLD valueColor={data?.color}>
                  {formatNumber(
                    Math.round(
                      (value || value === 0 ? Number(value) : data?.value) ??
                        NaN
                    ),
                    selectedTabId === TAB_ID.RISKS ? undefined : "%",
                    0
                  )}
                </ValueVLD>
              </>
            )}

            {data?.id === "risks" && (
              <ValueVLD>
                {projects ?? "-"}
                <ValueColorRiskVLD> / </ValueColorRiskVLD>
                <ValueColorRiskVLD valueColor={color}>
                  {formatNumber(value, undefined, 0) ?? "-"}
                </ValueColorRiskVLD>
              </ValueVLD>
            )}
          </>
        )}

        {achievementEnabled && currentRegions && currentRegions?.length > 0 && (
          <>
            <RegionsTitle>
              <Text>Рейтинг</Text>
            </RegionsTitle>

            <WrapperScrollbar style={{ overflow: "none !important" }}>
              <CustomScrollbar heightMax={240} autoHeight>
                {currentRegions
                  ?.filter(({ id }) => Boolean(id))
                  ?.map((region) => (
                    <RegionsItem
                      key={region.id}
                      isSelect={
                        region.id === selectedRegion?.id && !!selectedRegion?.id
                      }
                      direction="row"
                      gap={2}
                    >
                      <RegionsItemNumber colorValue={region?.color}>
                        {region?.position}
                      </RegionsItemNumber>
                      <div>{region?.name}</div>
                      <RegionsItemFact colorValue={region?.color}>
                        {region?.fact}
                      </RegionsItemFact>
                    </RegionsItem>
                  ))}
              </CustomScrollbar>
            </WrapperScrollbar>
          </>
        )}
      </WrapperContent>
      <ButtonStyled variant="contained" onClick={handleClick}>
        Подробнее
      </ButtonStyled>
      {data?.id !== "risks" && date && <ValueDate>{date}</ValueDate>}
    </Wrapper>
  );
};
