import moment from "moment";
import { useMemo, useRef } from "react";
import { useParams } from "react-router";

import { useVDL } from "@/features/VdlLeader/hooks/useVDL";
import { useRegion } from "@/pages/RegionPage/hooks/useRegion";
import { BlockWpapper } from "@/shared/BlockWrapper/BlockWrapper";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { formatNumber } from "@/shared/lib/heplers";
import { useRegionPopUp } from "@/shared/RegionPopUp/hooks/useRegionPopUp";
import { IPopUpProps, REGION_TABLES } from "@/shared/RegionPopUp/lib/types";
import { ValueDate } from "@/shared/ValueDate/ValueDate";

import { getActualIndicator } from "../lib/helpers";
import { Leader } from "./Leader/ui/Leader";
import { NationalGoalsLink } from "./NationalGoals/NationalGoalsLink";
import {
  LeaderWrapper,
  LinkDateWrapper,
  StatisticContainer,
  StatisticNumber,
  StatisticPLaceInfo,
  Statistics,
  StatisticsWrapper,
  TextStyled,
  VDLHeader,
  VDLStyled,
} from "./VdlLeader.styled";

export const VdlLeader = ({ isOpenPopUp }: IPopUpProps) => {
  const { id, tabId } = useParams<"id" | "tabId">();
  const { data, leaderData } = useVDL();
  const showRef = useRef<HTMLDivElement | null>(null);
  const { move, handleMove, handleChange } = useOpenIncident();
  const linkAttributes = useRegion(id!)?.attributeVals;
  const { PopUp, PopUpButton }: any = useRegionPopUp(
    REGION_TABLES.VDL,
    isOpenPopUp,
    "Высшее должностное лицо"
  );

  const dataVdl = useMemo(() => {
    const defaultVdl = "105";
    const vdl = getActualIndicator(tabId!!);
    const checkValueVdl = data?.[vdl]?.indicatorVals?.["621"];

    return data?.[checkValueVdl ? vdl : defaultVdl];
  }, [tabId, data]);

  const [startDate, partDate, reportDate] = useMemo(() => {
    const data = dataVdl?.attributeVals;
    const startDate = moment(data?.DateStart).format("YYYY-MM-DD");
    const partDate = data?.DatePart?.toLowerCase();
    const reportDate = data?.["REPORT_DATE"];
    return [startDate, partDate, reportDate];
  }, [dataVdl]);

  return (
    <VDLStyled
      isOpenPopUp={!!isOpenPopUp}
      ref={showRef}
      onMouseEnter={() => handleMove(true)}
      onMouseLeave={() => handleMove(false)}
    >
      <BlockWpapper
        hasData={!!Object.keys({ ...leaderData, ...data } || {})?.length}
        noDataText="Нет данных"
      >
        <StatisticsWrapper openWidget={isOpenPopUp}>
          <VDLHeader>
            <LeaderWrapper isOpenPopUp={isOpenPopUp}>
              <Leader isOpenPopUp={isOpenPopUp} />
            </LeaderWrapper>

            {move && !isOpenPopUp && (
              <IncidentCreateButton
                posRight={48}
                onClick={(event) => {
                  handleChange(
                    event,
                    showRef?.current,
                    "Оценка ВДЛ",
                    reportDate
                  );
                }}
              />
            )}
            {!isOpenPopUp && <PopUpButton />}
          </VDLHeader>

          <Statistics>
            <StatisticPLaceInfo>
              <StatisticContainer isOpenPopUp={isOpenPopUp}>
                <StatisticNumber isOpenPopUp={isOpenPopUp}>
                  {formatNumber(dataVdl?.indicatorVals?.["621"], undefined, 0)}
                </StatisticNumber>

                {dataVdl?.UNIT}
              </StatisticContainer>

              <TextStyled isOpenPopUp={isOpenPopUp}>
                {dataVdl?.short_name}
              </TextStyled>
            </StatisticPLaceInfo>
          </Statistics>
        </StatisticsWrapper>
      </BlockWpapper>

      <LinkDateWrapper openWidget={isOpenPopUp}>
        <NationalGoalsLink
          dateStart={startDate}
          datePart={partDate}
          name={linkAttributes?.name}
          codeFns={linkAttributes?.code_fns}
        />

        <ValueDate isOpenPopUp={isOpenPopUp}>{reportDate}</ValueDate>
      </LinkDateWrapper>

      <PopUp />
    </VDLStyled>
  );
};
