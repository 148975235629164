import { useAppSelector } from "@/store/redux/hooks";

export const useRisks = () => {
  const {
    sumTotalRisk: { sumRisksTotal },
    sumRfFoRisk: { sumRfFoRisks },
    risksKpi: { risksKpi },
  } = useAppSelector((state) => state.regions);

  return { sumRfFoRisks, sumRisksTotal, risksKpi };
};
