import { TableBody, TableHead, TableRow } from "@mui/material";
import { useTheme } from "@mui/material";
import React, { FC, useRef } from "react";

import { useProjectFinances } from "@/pages/ProjectsPage/hooks/useProjectFinances";
import { STICKY_INDEX_FINANCE } from "@/pages/ProjectsPage/lib/constants";
import { BlockWpapper } from "@/shared/BlockWrapper/BlockWrapper";
import { CardList } from "@/shared/CardList/CardList.styled";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { useCheckpointsFilter } from "@/shared/InputSelect/hooks/useCheckpointsFilter";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";
import { StickyTableCell } from "@/shared/TableBase/BasicTable.styled";
import { useStickyTableCells } from "@/shared/TableBase/hooks/useStickyTableCells";

import {
  ProjectTable,
  ProjectTableCell,
  ProjectTitle,
  ProjectTitleText,
} from "../common/ProjectTable.styled";
import { UnitMeasureTitle } from "./ProjectFinancesTable.styled";
import { UnitMeasureGroup } from "./UnitMeasureGroup";

export const ProjectFinancesTable: FC = () => {
  const {
    rows,
    columnRows,
    unitMeasure,
    handleUnitMeasure,
    isCheckFinancesPeriods,
    financesFetching,
  } = useProjectFinances();
  const theme = useTheme();
  const showRef = useRef<HTMLDivElement | null>(null);
  const { move, handleMove, handleChange } = useOpenIncident();
  const { actualPeriodData } = useCheckpointsFilter();
  const reportDate = actualPeriodData?.attributeVals.REPORT_DATE || "";

  const { getSticky: getStickyFirstRow } = useStickyTableCells(
    columnRows[0],
    STICKY_INDEX_FINANCE
  );
  const { getSticky: getStickySecondRow } = useStickyTableCells(
    columnRows[1],
    0,
    getStickyFirstRow(5).stickyPosition
  );

  return (
    <CardList
      ref={showRef}
      onMouseEnter={() => handleMove(true)}
      onMouseLeave={() => handleMove(false)}
      sx={{ position: "relative" }}
    >
      <ProjectTitle>
        <ProjectTitleText>Финансирование</ProjectTitleText>
        {move && (
          <IncidentCreateButton
            bigSize
            posRight={!isCheckFinancesPeriods ? 24 : 490}
            onClick={(event) => {
              handleChange(
                event,
                showRef?.current,
                "Финансирование",
                reportDate
              );
            }}
          />
        )}

        {isCheckFinancesPeriods && (
          <>
            <UnitMeasureTitle>Ед. измерения</UnitMeasureTitle>
            <UnitMeasureGroup
              unitMeasure={unitMeasure}
              handleUnitMeasure={handleUnitMeasure}
            />
          </>
        )}
      </ProjectTitle>
      {financesFetching ? (
        <Sceleton type="line" heightLine={84} />
      ) : (
        <BlockWpapper
          hasData={isCheckFinancesPeriods}
          noDataText="Данные отсутствуют"
          sx={{
            fontSize: "1.12rem",
            background: theme.palette.background.paper,
          }}
        >
          <ProjectTable fullHeight horizontalBorders>
            <TableHead>
              {columnRows.map((columns, index) => {
                let getSticky = getStickyFirstRow;

                switch (index) {
                  case 1:
                    getSticky = getStickySecondRow;
                    break;
                }

                return (
                  <TableRow key={index}>
                    {columns.map(({ id, label, ...column }, index) => (
                      <ProjectTableCell
                        key={id}
                        align={id === "number" ? "center" : undefined}
                        {...column}
                        {...getSticky(index)}
                      >
                        {label}
                      </ProjectTableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableHead>
            <TableBody>
              {rows.map((cells, rowIndex) => {
                return (
                  <TableRow key={rowIndex}>
                    {cells.map(({ id, value, ...cell }, index) => {
                      const isTotalCell =
                        id === "short_name" && value === "Итого";
                      const sticky =
                        index < STICKY_INDEX_FINANCE
                          ? getStickyFirstRow(
                              rowIndex === 3 ? index + 1 : index
                            )
                          : getStickySecondRow(index - STICKY_INDEX_FINANCE);

                      return (
                        <StickyTableCell
                          key={id}
                          {...cell}
                          {...sticky}
                          align={id === "number" ? "center" : undefined}
                          style={id === "short_name" ? { zIndex: 2 } : null}
                          colSpan={isTotalCell ? 2 : 1}
                          stickyPosition={
                            isTotalCell ? 0 : sticky.stickyPosition
                          }
                        >
                          {value ?? "-"}
                        </StickyTableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </ProjectTable>
        </BlockWpapper>
      )}
    </CardList>
  );
};
