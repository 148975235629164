import {
  IPreparedOlapResponseDimensionItemDim,
  IRegionAttributeVals,
} from "@/store/scheme/olap";

import { COMPARE_TYPE } from "../CompareControl/lib/types";
import { CompareTitle, FactHighLow } from "./types";

export const PUBLIC_URL = process.env.PUBLIC_URL;
export const REACT_APP_BRANCH = process.env.REACT_APP_BRANCH;
export const isDevBranch = ["DEV"].includes(REACT_APP_BRANCH!!);
export const isTestBranch = ["DEV", "DEMO"].includes(REACT_APP_BRANCH!!);

export const REGION_COUNTRY_CODE = "8";
export const REGION_DISTRICT_CODES = [
  "9",
  "25",
  "34",
  "39",
  "45",
  "56",
  "61",
  "71",
];

export const REGION_COATS = {
  "8": `${PUBLIC_URL}/regions/Russia.svg`,
  "9": `${PUBLIC_URL}/regions/cfo.svg`,
  "9-light": `${PUBLIC_URL}/regions/cfo_light.svg`,

  "10": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Belgorod_Oblast.svg`,
  "11": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Bryansk_Oblast.svg`,
  "12": `${PUBLIC_URL}/regions/Coat_of_arms_of_Vladimiri_Oblast.svg`,
  "13": `${PUBLIC_URL}/regions/Coat_of_arms_of_Voronezh_Oblast.svg`,
  "14": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Ivanovo_Oblast.svg`,
  "15": `${PUBLIC_URL}/regions/Coat_of_arms_of_Kaluga_Oblast.svg`,
  "16": `${PUBLIC_URL}/regions/Coat_of_arms_of_Kostroma_Oblast.svg`,
  "17": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Kursk_oblast.svg`,
  "18": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Lipetsk_oblast.svg`,
  "19": `${PUBLIC_URL}/regions/Coat_of_arms_of_Moscow_Oblast_(large).svg`,
  "20": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Ryazan_oblast.svg`,
  "21": `${PUBLIC_URL}/regions/Coat_of_arms_of_Smolensk_oblast.svg`,
  "22": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Tver_oblast.svg`,
  "23": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Tula_oblast.svg`,
  "24": `${PUBLIC_URL}/regions/Coat_of_arms_of_Yaroslavl_Oblast.svg`,

  "25": `${PUBLIC_URL}/regions/Severo-zapadnyiFO.svg`,
  "25-light": `${PUBLIC_URL}/regions/Severo-zapadnyiFO_light.svg`,

  "26": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Republic_of_Karelia.svg`,
  "27": `${PUBLIC_URL}/regions/Coat_of_Arms_of_the_Komi_Republic.svg`,
  "28": `${PUBLIC_URL}/regions/Coat_of_arms_of_Nenets_Autonomous_Okrug.svg`,
  "29": `${PUBLIC_URL}/regions/Coat_of_arms_of_Vologda_oblast.svg`,
  "30": `${PUBLIC_URL}/regions/Coat_of_arms_of_Leningrad_Oblast.svg`,
  "31": `${PUBLIC_URL}/regions/Coat_of_arms_of_Murmansk_Oblast.svg`,
  "32": `${PUBLIC_URL}/regions/Coat_of_arms_of_Pskov_Oblast_(2018).svg`,
  "33": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Saint_Petersburg_(2003).svg`,

  "34": `${PUBLIC_URL}/regions/YuzhnyiFO.svg`,
  "34-light": `${PUBLIC_URL}/regions/YuzhnyiFO_light.svg`,

  "35": `${PUBLIC_URL}/regions/Coat_of_arms_of_Adygea.svg`,
  "36": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Krasnodar_Kray.svg`,
  "37": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Astrakhan_Oblast.svg`,
  "38": `${PUBLIC_URL}/regions/Coat_of_arms_of_Rostov_Oblast.svg`,

  "39": `${PUBLIC_URL}/regions/Severo-kavkazskiyFO.svg`,
  "39-light": `${PUBLIC_URL}/regions/Severo-kavkazskiyFO_light.svg`,

  "40": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Dagestan.svg`,
  "41": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Kabardino-Balkaria.svg`,
  "42": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Karachay-Cherkessia.svg`,
  "43": `${PUBLIC_URL}/regions/Coat_of_arms_of_Chechnya.svg`,
  "44": `${PUBLIC_URL}/regions/Coat_of_arms_of_Stavropol_Krai.svg`,

  "45": `${PUBLIC_URL}/regions/PrivolzhskyiFO.svg`,
  "45-light": `${PUBLIC_URL}/regions/PrivolzhskyiFO_light.svg`,

  "46": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Bashkortostan.svg`,
  "47": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Mordovia.svg`,
  "48": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Tatarstan.svg`,
  "49": `${PUBLIC_URL}/regions/Coat_of_arms_of_Udmurtia.svg`,
  "50": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Perm_Krai.svg`,
  "51": `${PUBLIC_URL}/regions/Coat_of_arms_of_Kirov_Region.svg`,
  "52": `${PUBLIC_URL}/regions/Coat_of_arms_of_Orenburg_Oblast.svg`,
  "53": `${PUBLIC_URL}/regions/Coat_of_arms_of_Penza_Oblast.svg`,
  "54": `${PUBLIC_URL}/regions/Coat_of_arms_of_Samara_Oblast.svg`,
  "55": `${PUBLIC_URL}/regions/Coat_of_arms_of_Ulyanovsk_Oblast.svg`,

  "56": `${PUBLIC_URL}/regions/UralskiyFO.svg`,
  "56-light": `${PUBLIC_URL}/regions/UralskiyFO_light.svg`,

  "57": `${PUBLIC_URL}/regions/Coat_of_arms_of_Kurgan_Oblast.svg`,
  "58": `${PUBLIC_URL}/regions/Coat_of_arms_of_Tyumen_Oblast.svg`,
  "59": `${PUBLIC_URL}/regions/Coat_of_arms_of_Yugra_(Khanty-Mansia).svg`,
  "60": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Yamal_Nenetsia.svg`,

  "61": `${PUBLIC_URL}/regions/SibirskiyFO.svg`,
  "61-light": `${PUBLIC_URL}/regions/SibirskiyFO_light.svg`,

  "62": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Altai_Republic.svg`,
  "64": `${PUBLIC_URL}/regions/Coat_of_arms_of_Khakassia.svg`,
  "65": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Altai_Krai.svg`,
  "66": `${PUBLIC_URL}/regions/Coat_of_arms_of_Krasnoyarsk_Krai.svg`,
  "67": `${PUBLIC_URL}/regions/Coat_of_arms_of_Irkutsk_Oblast.svg`,
  "68": `${PUBLIC_URL}/regions/Coat_of_arms_of_Novosibirsk_oblast.svg`,
  "69": `${PUBLIC_URL}/regions/Wappen_von_Oblast_Omsk_(seit_2020).svg`,
  "70": `${PUBLIC_URL}/regions/Coat_of_arms_of_Tomsk_Oblast.svg`,

  "71": `${PUBLIC_URL}/regions/DalnevostochnyiFO.svg`,
  "71-light": `${PUBLIC_URL}/regions/DalnevostochnyiFO_light.svg`,

  "72": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Kamchatka_Krai.svg`,
  "73": `${PUBLIC_URL}/regions/Coat_of_arms_of_Primorsky_Krai.svg`,
  "74": `${PUBLIC_URL}/regions/Coat_of_arms_of_Amur_Oblast.svg`,
  "75": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Magadan_oblast.svg`,
  "76": `${PUBLIC_URL}/regions/Coat_of_arms_of_the_Jewish_Autonomous_Oblast.svg`,
  "77": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Chukotka.svg`,
  "94": `${PUBLIC_URL}/regions/COA_of_Sevastopol.svg`,
  "95": `${PUBLIC_URL}/regions/Emblem_of_Crimea.svg`,
  //"96": `${PUBLIC_URL}/regions/Не определено",
  //"97": `${PUBLIC_URL}/regions/г. Байконур",
  "101": `${PUBLIC_URL}/regions/Coat_of_arms_of_Oryol_Oblast.svg`,
  "102": `${PUBLIC_URL}/regions/Coat_of_arms_of_Tambov_Oblast.svg`,
  "103": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Moscow.svg`,
  "104": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Arkhangelsk_oblast.svg`,
  "105": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Kaliningrad_Oblast.svg`,
  "106": `${PUBLIC_URL}/regions/Coat_of_arms_of_Novgorod_Oblast.svg`,
  "107": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Kalmykia.svg`,
  "108": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Volgograd_oblast.svg`,
  "109": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Ingushetia.svg`,
  "110": `${PUBLIC_URL}/regions/Wapen_Ossetien.svg`,
  "111": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Mari_El.svg`,
  "112": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Chuvashia.svg`,
  "113": `${PUBLIC_URL}/regions/Coat_of_arms_of_Nizhny_Novgorod_Region.svg`,
  "114": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Saratov_oblast.svg`,
  "115": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Sverdlovsk_oblast.svg`,
  "116": `${PUBLIC_URL}/regions/Coat_of_arms_of_Chelyabinsk_Oblast.svg`,
  "117": `${PUBLIC_URL}/regions/Coat_of_arms_of_Tuva.svg`,
  "119": `${PUBLIC_URL}/regions/Coat_of_arms_of_Kemerovo_Oblast.svg`,
  "120": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Sakha_(Yakutia).svg`,
  "121": `${PUBLIC_URL}/regions/Coat_of_arms_of_Khabarovsk_Krai.svg`,
  "122": `${PUBLIC_URL}/regions/Sakhalin_Oblast_Coat_of_Arms.svg`,
  "123": `${PUBLIC_URL}/regions/Coat_of_Arms_of_Buryatia.svg`,
  "124": `${PUBLIC_URL}/regions/Coat_of_arms_of_Zabaykalsky_Krai.svg`,
};

export const REGION_REPORTS = {
  "9": "ЦФО",
  "25": "СЗФО",
  "39": "СКФО",
  "34": "ЮФО",
  "71": "ДФО",
  "61": "СФО",
  "56": "УФО",
  "45": "ПФО",
  "8": "РФ",
};

export const REGIONS_STARTER: IPreparedOlapResponseDimensionItemDim<IRegionAttributeVals> =
  {
    id: REGION_COUNTRY_CODE,
    code: REGION_COUNTRY_CODE,
    attributeVals: {
      name: "Российская Федерация",
      code_fk: "",
      code_fns: "",
      code_olap: "",
      adm_center: "",
      SHORT_NAME: "",
    },
    childrenCnt: 0,
    level: 0,
    parentId: [],
    queryParentId: [],
  };

export const factHighLow: FactHighLow = {
  [COMPARE_TYPE.FO]: "factHighLowFO",
  [COMPARE_TYPE.RF]: "factHighLowRF",
};

export const compareTitle: CompareTitle = {
  [COMPARE_TYPE.FO]: "ФО",
  [COMPARE_TYPE.RF]: "РФ",
};

export const compareRisks: Record<string, string> = {
  "0": "Без риска",
  "1": "Средний риск",
  "2": "Высокий риск",
};
