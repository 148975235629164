import { useTheme } from "@mui/material";
import ReactECharts from "echarts-for-react";
import React, { useMemo } from "react";

import { useRegion } from "@/pages/RegionPage/hooks/useRegion";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { BlockWpapper } from "@/shared/BlockWrapper/BlockWrapper";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { useTotalGraph } from "@/widgets/IndicatorGraph/hooks/useTotalGraph";
import {
  barInidcarors,
  defaultIrregularIndicators,
  irregularIndicators,
  isYAxisIndicator,
} from "@/widgets/IndicatorGraph/lib/constants";

import {
  getFormatterByTop,
  getLabelStyle,
  getXaxisLabelStyle,
  prepareGraphOptions,
  richAxisLabel,
  xAxisOptions,
} from "../../lib/helpers";
import { IIndicatorPassportGraphProps } from "../../lib/types";
import { StackGraphStyled } from "../IndicatopPassport.styled";

export const IndicatorPassportGraph = ({
  indicatorId,
  activeCompare,
}: IIndicatorPassportGraphProps) => {
  const theme = useTheme();
  const { prepareData, xAxisPeriods, fetching, pathId } =
    useTotalGraph(indicatorId);
  const region = useRegion(pathId!);
  const { getDistrictByRegion, districtIds, isCheckRfFoPage } = useRegions();

  // Данные для отображения в легенде и type графика
  const [regionName, districtName] = useMemo(() => {
    return [
      pathId !== REGION_COUNTRY_CODE
        ? region?.attributeVals?.[
            !["27"].includes(indicatorId!!)
              ? districtIds?.includes(region?.id!!)
                ? "SHORT_NAME"
                : "name"
              : "adm_center"
          ]
        : irregularIndicators.includes(indicatorId!!)
        ? ["27"].includes(indicatorId!!)
          ? "г. Москва"
          : "РФ"
        : undefined,
      !(
        ["27"].includes(indicatorId!!) ||
        (isCheckRfFoPage.isRF && irregularIndicators.includes(indicatorId!!))
      )
        ? activeCompare === COMPARE_TYPE.RF
          ? ["7"].includes(indicatorId!!)
            ? "Общее количество мест"
            : "РФ"
          : getDistrictByRegion(region?.id!!)?.attributeVals?.["SHORT_NAME"] ||
            ""
        : undefined,
    ];
  }, [pathId, activeCompare, region, districtIds, isCheckRfFoPage.isRF]);

  const graphOptions = useMemo(() => {
    const isBar = barInidcarors.includes(indicatorId!!);
    const options = {
      ...prepareGraphOptions(
        theme,
        (isYAxisIndicator?.includes(indicatorId!!) && !isCheckRfFoPage.isRF) ||
          (isCheckRfFoPage.isRF &&
            defaultIrregularIndicators.includes(indicatorId!!)),
        isBar ? "bar" : undefined,
        ["7"].includes(indicatorId!!) ? "line" : undefined,
        regionName,
        districtName,
        isCheckRfFoPage.isRF
      ),
    };
    // Формирование options для графика
    const chartOpt = {
      ...options,
      xAxis: [
        {
          ...options.xAxis,
          data: [...xAxisPeriods].map((period: string) => ({
            value: period,
            textStyle: getXaxisLabelStyle(theme, ["7"].includes(indicatorId!!)),
          })),
        },
        ...(!["27", "7", ...defaultIrregularIndicators].includes(
          indicatorId!!
        ) && !isCheckRfFoPage.isRF
          ? [
              {
                ...xAxisOptions(),
                axisLabel: {
                  ...xAxisOptions().axisLabel,
                  formatter: (value: any) => {
                    return getFormatterByTop(value);
                  },
                  rich: richAxisLabel(theme),
                },
                data: [...prepareData]?.map(
                  ({ factRegion, factDistrict, factRF, fractionDigits }) => {
                    const changeFact =
                      activeCompare === "RF" ? factRF : factDistrict;
                    return {
                      value: [factRegion, changeFact, fractionDigits],
                    };
                  }
                ),
              },
            ]
          : []),
      ],
      series: options?.series?.map((option) => ({
        ...option,
        data: [...prepareData]?.map(
          ({
            factRegion,
            factDistrict,
            factRF,
            fractionDigits,
            indicatorRating,
          }) => {
            let value, changeFact;
            if (["7"].includes(indicatorId!!)) {
              // Для Рейтинга (7) нужно брать данные из indicatorRating
              value =
                option.typeName === "childFact" ? factRegion : indicatorRating;
            } else {
              // Данные берем в зависимости от положения свитчера РФ/ФО
              changeFact = activeCompare === "RF" ? factRF : factDistrict;
              value = option.typeName === "childFact" ? factRegion : changeFact;
            }

            return {
              value,
              label: {
                ...getLabelStyle(
                  theme,
                  ["27"].includes(indicatorId!!),
                  +fractionDigits,
                  option.typeName
                ),
                show:
                  // Показываем значения только для типа bar или на странице РФ
                  ["27", "7", ...defaultIrregularIndicators].includes(
                    indicatorId!!
                  ) || isCheckRfFoPage.isRF,
                // Формирование стилей для типа bar или на странице РФ
                ...((barInidcarors.includes(indicatorId!!) ||
                  isCheckRfFoPage.isRF) && {
                  position: (value as number) >= 0 ? "top" : "bottom",
                  align: "center",
                  offset: [0, (value as number) >= 0 ? -6 : 6],
                  // Формирование стилей для типа bar в паспорте Рейтинга
                  ...(["7"].includes(indicatorId!!) && {
                    position:
                      option.typeName === "childFact" ? "inside" : "top",
                    color:
                      theme.palette.graphs[
                        option.typeName === "childFact"
                          ? "graphsRatingRegion"
                          : "planLabel"
                      ],
                    fontWeight: 500,
                    offset: [0, 0],
                  }),
                  // Формирование стилей на странице РФ, кроме графика с типом bar
                  ...(isCheckRfFoPage.isRF &&
                    !defaultIrregularIndicators.includes(indicatorId!!) && {
                      offset: [0, -6],
                    }),
                }),
              },
            };
          }
        ),
      })),
    };
    return chartOpt;
  }, [
    theme,
    regionName,
    districtName,
    isCheckRfFoPage.isRF,
    indicatorId,
    prepareData,
    xAxisPeriods,
    activeCompare,
  ]);

  return (
    <StackGraphStyled direction="column">
      <BlockWpapper
        hasData={
          !!(prepareData || [])?.length &&
          !!Object.keys(graphOptions || {})?.length
        }
        isFetching={fetching}
        noDataText="Нет данных"
      >
        <ReactECharts
          option={graphOptions}
          style={{ overflow: "hidden", height: "100%", width: "100%" }}
        />
      </BlockWpapper>
    </StackGraphStyled>
  );
};
