import { useMemo } from "react";
import { useParams } from "react-router";

import { useSumProjects } from "@/features/RegionProjectsWidget/hooks/useSumProjects";
import { Rows } from "@/features/RegionProjectsWidget/lib/types";
import { useRegion } from "@/pages/RegionPage/hooks/useRegion";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { formatNumber } from "@/shared/lib/heplers";

import { riskValues } from "../lib/constants";
import { useRisks } from "./useRisks";
import { useRisksGraph } from "./useRisksGraph";

export const useRisksTable = () => {
  const { id } = useParams<"id">();
  const region = useRegion(id!);
  const { isCheckRfFoPage } = useRegions();
  const { rows: projectRows } = useSumProjects("120300000");
  const { sumRfFoRisks, sumRisksTotal, risksKpi } = useRisks();
  const { commonProjects } = useRisksGraph();

  const getTitle = () => {
    return isCheckRfFoPage.isRF ? "РФ" : region?.attributeVals?.["SHORT_NAME"];
  };

  const columns = useMemo(() => {
    const spanValue = "в зоне риска";
    const secondTitleColumn = isCheckRfFoPage.isAll ? (
      <div>
        Проекты / <span style={{ whiteSpace: "nowrap" }}>{spanValue}</span>
      </div>
    ) : (
      ""
    );

    const prepareColumns = [
      {
        id: 1,
        title: "",
        isShowBody: false,
      },
      {
        id: 2,
        title: secondTitleColumn,
        isShowBody: true,
      },
    ];
    if (isCheckRfFoPage.isAll) {
      prepareColumns.push({
        id: 3,
        title: "",
        isShowBody: true,
      });
    }
    return prepareColumns;
  }, [isCheckRfFoPage, region]);

  const rows = useMemo(() => {
    let prepareRows = [] as Rows[];
    if (!isCheckRfFoPage.isAll) {
      prepareRows = [...projectRows?.filter(({ id }) => id !== "0")]?.map(
        (row) => {
          const indicators =
            sumRisksTotal?.[id!!]?.[row.id]?.["151"]?.indicatorVals;
          row["2"] = indicators?.[riskValues.status];
          row["color"] = indicators?.[riskValues.color];
          return row;
        }
      );
    } else {
      const totalSubject = {
        id: "0",
        name: `Итого по ${getTitle()}`,
        total: commonProjects?.value,
        color:
          sumRisksTotal?.[id!!]?.[0]?.["152"]?.indicatorVals?.[
            riskValues.color
          ],
        risk: formatNumber(
          sumRisksTotal?.[id!!]?.[0]?.["152"]?.indicatorVals?.[riskValues.fact],
          undefined,
          0
        ),
        riskPercent: formatNumber(
          sumRisksTotal?.[id!!]?.[0]?.["152"]?.indicatorVals?.[
            riskValues.percent
          ],
          "%",
          0
        ),
      };
      const prepareSubjectRows = Object.values(risksKpi || {})
        ?.map((row: any) => {
          const indicatorsRisk =
            sumRfFoRisks?.[row?.id as any]?.[0]?.["152"]?.indicatorVals || {};
          const risk = formatNumber(
            indicatorsRisk?.[riskValues.fact],
            undefined,
            0
          );
          const riskPercent = formatNumber(
            indicatorsRisk?.[riskValues.percent],
            "%",
            0
          );
          const color = indicatorsRisk?.[riskValues.color];
          return { ...row, risk, riskPercent, color };
        })
        ?.sort((rowA, rowB) =>
          (parseInt(rowA?.riskPercent) || 0) <
          (parseInt(rowB?.riskPercent) || 0)
            ? 1
            : -1
        );

      prepareRows = [totalSubject, ...prepareSubjectRows];
    }
    return prepareRows;
  }, [
    projectRows,
    sumRisksTotal,
    id,
    sumRfFoRisks,
    risksKpi,
    isCheckRfFoPage.isAll,
    isCheckRfFoPage.isFO,
    commonProjects,
  ]);

  return { columns, rows };
};
