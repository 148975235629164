import "moment/locale/ru";

import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { setAuthHeader } from "@store/api/client";
import { actions as tokenActions } from "@store/redux/slices/auth/tokenSlice";
import { actions as userActions } from "@store/redux/slices/auth/userSlice";
import { AppBar } from "@widgets/AppBar/ui/AppBar";
import moment from "moment";
import { createContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";

import { modeSet } from "@/app/theme/model/modeSlice";
import { useAllVDL } from "@/features/AchievementVdl/hooks/useAllVdl";
import { geoExtraActions } from "@/features/Map/model/geoSlice";
import { useNationalProjects } from "@/features/NationalProjectsIndicators/hooks/useNationalProjects";
import { useVDL } from "@/features/VdlLeader/hooks/useVDL";
import { useRisksSubjectsProjects } from "@/pages/MainPage/hooks/useRisksSubjectsProjects";
import { useProjects } from "@/pages/ProjectsPage/hooks/useProjects";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { useIncident } from "@/shared/Incident/hooks/useIncident";
import { usePeriod } from "@/shared/PeriodControl/hooks/usePeriod";
import { periodIsUpdate } from "@/shared/PeriodControl/model/periodSlice";
import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { CustomScrollbar } from "../../../shared/CustomScrollbar/ui/CustomScrollbar";
import { Loader } from "../../../shared/Loader/Loader";
import { Notifications } from "../../../shared/Notifications/ui/Notifications";
import { MainMenu } from "../../../widgets/MainMenu/ui/MainMenu";
import usePrevious from "../../hooks/usePrevious";
import commonTheme from "../../theme/common";
import darkTheme from "../../theme/dark";
import lightTheme from "../../theme/light";
import { renderContentByRoutes } from "../lib/helpers";
import { Content, ContentScrollbar, RootContainer } from "./App.styled";

// 6, 8, 12

moment.locale("ru");
export const RootContainerContext = createContext(null);

const App = () => {
  const { pathname } = useLocation();
  const [isLightTheme, setIsLightTheme] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const rootContainerRef = useRef(null);
  const [isInit, setIsInit] = useState(false);
  const {
    fetching: tokenFetching,
    type,
    access_token,
  } = useAppSelector((state) => state.auth.token);
  const {
    fetching: userFetching,
    user,
    userError,
  } = useAppSelector((state) => state.auth.user);
  const { currentPeriod, isUpdatePeriod } = useAppSelector(
    (state) => state.controls.period
  );

  const { fetching: fetchingRegions } = useAppSelector(
    (state) => state.regions.regions
  );

  const regionId = useAppSelector((state) => state.controls.regions.id);

  const prevTokenFetching = usePrevious(tokenFetching);
  const prevUserFetching = usePrevious(userFetching);

  const isDarkMode = !useMemo(() => /^\/(light)/.test(pathname), [pathname]);

  const {
    requestRegions,
    regionIds,
    requestRegionsByDistrict,
    districtIds,
    isCheckRfFoPage,
  } = useRegions();
  const { requestNationalProjects, requestNationalProjectsAll } =
    useNationalProjects();
  const {
    requestProjectsDirectory,
    projectsDirectory,
    projectsDirectoryFetching,
    projectsDirectoryError,
  } = useProjects();
  const { requestVDL } = useVDL();
  const {
    periodData,
    requestPeriodFilter,
    periodDataFetching,
    requestDataByPeriod,
    periodError,
  } = usePeriod();
  const { requestAllVDL, allSortedAllVdl, allVdlError } = useAllVDL();
  const { requestFieldsIncidents } = useIncident();
  const { requestRiskTable } = useRisksSubjectsProjects();
  const { isCheckRiskFuturePlan } = useFuturePlan();

  // Переключение темы
  useEffect(() => {
    dispatch(modeSet(isDarkMode ? "dark" : "light"));
  }, [dispatch, isDarkMode]);

  /* Сначала пробуем авторизоваться по oam_remote_user */
  useEffect(() => {
    dispatch(tokenActions.getToken({ type: "oam_remote_user" }));
  }, [dispatch]);

  /* Если не получилось, пробуем авторизоваться по password  */
  useEffect(() => {
    if (
      type === "oam_remote_user" &&
      prevTokenFetching &&
      !tokenFetching &&
      !access_token
    ) {
      dispatch(tokenActions.getToken({ type: "password" }));
    }
  }, [access_token, dispatch, prevTokenFetching, tokenFetching, type]);

  useEffect(() => {
    if (prevTokenFetching && !tokenFetching) {
      if (!access_token && type === "oam_remote_user") {
        // Если oam_remote_user не прошел, попробуем еще password
        return;
      }

      if (access_token) {
        setAuthHeader(access_token);
      }

      if (!user) {
        dispatch(userActions.getAuthorized(type));
      } else {
        setIsInit(true);
      }
    }
  }, [access_token, dispatch, tokenFetching, prevTokenFetching, user, type]);

  useEffect(() => {
    if (prevUserFetching && !userFetching) {
      setIsInit(true);
    }
  }, [prevUserFetching, userFetching]);

  useEffect(() => {
    if (isInit && user) {
      requestRegions();
      requestNationalProjectsAll();
      requestFieldsIncidents();
    }
  }, [isInit, requestNationalProjectsAll, requestRegions, user]);

  useEffect(() => {
    if (isInit && user && fetchingRegions) {
      requestRegionsByDistrict(districtIds);
    }
  }, [districtIds, fetchingRegions, isInit, requestRegionsByDistrict, user]);

  useEffect(() => {
    if (user && isInit && pathname.startsWith("/region") && regionId) {
      requestNationalProjects(regionId);
      if (!isCheckRfFoPage.isAll) {
        requestVDL(regionId);
      } else {
        if (!allSortedAllVdl?.length && !allVdlError) {
          requestAllVDL();
        }
      }
    }
  }, [
    regionId,
    requestNationalProjects,
    requestVDL,
    user,
    isInit,
    requestAllVDL,
    isCheckRfFoPage.isAll,
  ]);

  useEffect(() => {
    if (user && isInit && pathname.startsWith("/region") && regionId) {
      requestDataByPeriod(regionId);
    }
  }, [regionId, user, isInit, requestDataByPeriod]);

  useEffect(() => {
    if (
      user &&
      isInit &&
      (pathname.startsWith("/achievementLevel") ||
        pathname.startsWith("/selectedDistrict") ||
        pathname.startsWith("/district") ||
        pathname === "/") &&
      !isUpdatePeriod
    ) {
      dispatch(geoExtraActions.getProjectPassport(currentPeriod));
      dispatch(geoExtraActions.getKpiData(currentPeriod));
      dispatch(geoExtraActions.getProjectPassportForProjects(currentPeriod));
      dispatch(periodIsUpdate(true));
      if (isCheckRiskFuturePlan) {
        requestRiskTable(currentPeriod);
      }
    }
  }, [
    currentPeriod,
    dispatch,
    isInit,
    isUpdatePeriod,
    pathname,
    user,
    isCheckRiskFuturePlan,
  ]);

  useEffect(() => {
    if (
      user &&
      isInit &&
      !projectsDirectory.length &&
      !projectsDirectoryFetching &&
      !projectsDirectoryError
    ) {
      requestProjectsDirectory();
    }
  }, [
    projectsDirectory,
    projectsDirectoryFetching,
    requestProjectsDirectory,
    user,
    isInit,
  ]);

  useEffect(() => {
    if (user && isInit && !periodData && !periodDataFetching && !periodError) {
      requestPeriodFilter();
    }
  }, [periodData, periodDataFetching, requestPeriodFilter, user, isInit]);

  const isLoading =
    tokenFetching || userFetching || !isInit || (!userError && fetchingRegions);

  return (
    <ThemeProvider theme={commonTheme}>
      <ThemeProvider theme={!isLightTheme ? darkTheme : lightTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <RootContainer ref={rootContainerRef}>
            <AppBar withMenu={!!user} />

            {isLoading ? (
              <Content>
                <Loader />
              </Content>
            ) : (
              <>
                {user && (
                  <MainMenu
                    isLightTheme={isLightTheme}
                    setIsLightTheme={setIsLightTheme}
                  />
                )}

                <RootContainerContext.Provider value={rootContainerRef.current}>
                  <Content>
                    <ContentScrollbar>
                      <CustomScrollbar>
                        {renderContentByRoutes({ user, userError, regionIds })}
                      </CustomScrollbar>
                    </ContentScrollbar>
                  </Content>
                </RootContainerContext.Provider>

                {user && <Notifications />}
              </>
            )}
          </RootContainer>
        </LocalizationProvider>
      </ThemeProvider>
    </ThemeProvider>
  );
};

export default App;
