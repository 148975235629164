import { TableBody, TableRow } from "@mui/material";
import React from "react";

import { useRouter } from "@/app/hooks/useRouter";
import {
  LinkStyled,
  ProjectRowTitle,
  ProjectRowValue,
  TableRowHover,
  TitleCardListInner,
  TitleProject,
} from "@/features/RegionProjectsWidget/ui/RegionProjectsWidget.styled";
import { Icon } from "@/pages/MainPage/ui/VDL/VDLMain.styled";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { FoCoatsOfArms } from "@/shared/FoCoatsOfArms/ui/FoCoatsOfArms";
import { compareRisks, REGION_COATS } from "@/shared/lib/constants";
import { getUrlByTheme } from "@/shared/lib/heplers";
import { IPopUpProps } from "@/shared/RegionPopUp/lib/types";
import { TableHeadStyled } from "@/shared/WidgetScrollbar/ui/WidgetScrollbar.styled";

import { useRisksTable } from "../hooks/useRisksTable";
import {
  RisksHeaderCell,
  RisksTableWrapper,
  RisksValueFoRf,
  RiskTableCellStyled,
  RiskTableStyledstyled,
  RiskTableValue,
} from "../RisksWidget.styled";

export const RisksTable = ({ isOpenPopUp }: IPopUpProps) => {
  const { getPath } = useRouter();
  const { rows, columns } = useRisksTable();
  const { isCheckRfFoPage } = useRegions();
  const convertColumns = [...columns].filter((column) => column.isShowBody);

  return (
    <RisksTableWrapper>
      <RiskTableStyledstyled>
        <TableHeadStyled>
          <TableRow>
            {columns.map((column, index) => (
              <RisksHeaderCell
                key={column.id}
                isProjectBorder={!isCheckRfFoPage.isAll}
                openWidget={isOpenPopUp}
                indexChangeWidth={index}
              >
                {column.title}
              </RisksHeaderCell>
            ))}
          </TableRow>
        </TableHeadStyled>
        <TableBody>
          {rows?.map((row: any) => (
            <TableRowHover isOpenPopUp={!!isOpenPopUp} key={row.id}>
              <RiskTableCellStyled>
                <TitleCardListInner
                  sx={
                    row?.id !== "0"
                      ? { gridTemplateColumns: "3rem auto" }
                      : { gridTemplateColumns: "auto" }
                  }
                  isCheckRF={isCheckRfFoPage.isAll}
                >
                  {row?.number && (
                    <ProjectRowValue
                      sx={{
                        color: "rgba(255, 255, 255, 0.70)",
                        ...(isOpenPopUp ? { fontSize: "1.25rem" } : {}),
                      }}
                    >
                      {row.number}
                    </ProjectRowValue>
                  )}

                  {isCheckRfFoPage.isAll && row?.id !== "0" && (
                    <ProjectRowValue>
                      {isCheckRfFoPage.isFO ? (
                        <Icon
                          src={
                            REGION_COATS[row?.id as keyof typeof REGION_COATS]
                          }
                          achEnabled={true}
                          sx={{ width: "2rem", height: "2rem" }}
                        />
                      ) : (
                        <FoCoatsOfArms idFo={row?.id} />
                      )}
                    </ProjectRowValue>
                  )}

                  <ProjectRowTitle isCheckRF={isCheckRfFoPage.isAll}>
                    {row?.isNotJumpOnPage || isCheckRfFoPage.isAll ? (
                      <TitleProject
                        openWidget={isOpenPopUp}
                        isTotal={row?.id === "0"}
                      >
                        {row.name}
                      </TitleProject>
                    ) : (
                      <LinkStyled
                        to={getPath(getUrlByTheme(`/projects/${row.id}/risks`))}
                        sx={
                          isOpenPopUp
                            ? { fontSize: "1.3rem", lineHeight: "normal" }
                            : {}
                        }
                      >
                        {row.name}
                      </LinkStyled>
                    )}
                  </ProjectRowTitle>
                </TitleCardListInner>
              </RiskTableCellStyled>
              {!isCheckRfFoPage.isAll ? (
                <>
                  {convertColumns.map((col: any) => (
                    <RiskTableCellStyled key={col.id} sx={{ fontWeight: 500 }}>
                      <RiskTableValue
                        color={row.color}
                        openWidget={isOpenPopUp}
                      >
                        {compareRisks[row[col.id]] || "-"}
                      </RiskTableValue>
                    </RiskTableCellStyled>
                  ))}
                </>
              ) : (
                <>
                  <RiskTableCellStyled>
                    {["total", "risk"].map((column, index) => (
                      <RisksValueFoRf
                        column={column}
                        key={index}
                        openWidget={isOpenPopUp}
                        colorRisk={row?.color}
                        isTotal={column === "total"}
                      >
                        {row?.[column]}{" "}
                        {column === "total" && <span>&nbsp; / &nbsp; </span>}
                      </RisksValueFoRf>
                    ))}
                  </RiskTableCellStyled>
                  <RiskTableCellStyled>
                    <RisksValueFoRf
                      openWidget={isOpenPopUp}
                      column={"riskPercent"}
                      isPercent={true}
                    >
                      {row?.["riskPercent"]}
                    </RisksValueFoRf>
                  </RiskTableCellStyled>
                </>
              )}
            </TableRowHover>
          ))}
        </TableBody>
      </RiskTableStyledstyled>
    </RisksTableWrapper>
  );
};
