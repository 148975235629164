import { getUrlByTheme } from "@/shared/lib/heplers";

import { IDistrictValuesRow } from "./types";

// Проверяет и возвращает id индикатора или null в зависимости от отчетного периода
export const getIndicatorIdByPeriod = (
  indicatorId: string,
  reportPeriod: string
): string | null => {
  const isBindToReportDate: Boolean =
    (indicatorId === "1" && reportPeriod !== "MONTH") ||
    (indicatorId !== "1" && reportPeriod === "MONTH");

  if (isBindToReportDate) {
    return null;
  }
  return indicatorId;
};

export const titleWidgets = (indicatorId: string) => {
  switch (indicatorId) {
    case "1":
      return "Выпуск товаров и услуг";
    case "4":
      return "Число рабочих мест";
    case "132":
      return "Доходы населения";
    case "133":
      return "Частные инвестиции";
    default:
      return "Нет данных";
  }
};

export const getUrl = ({
  regionId,
  id,
  tabId,
}: Partial<Record<string, string>>) => {
  return getUrlByTheme(`/region/${regionId}/${tabId}`);
};

export const sortDistrictRows = (
  district: IDistrictValuesRow[],
  id?: string
) => {
  return district.sort((districtOne, districtTwo) => {
    if (districtOne?.id === id) {
      return -1;
    }
    if (districtTwo?.id === id) {
      return 1;
    }
    return districtOne?.title
      ?.toLowerCase()
      ?.localeCompare(districtTwo?.title?.toLowerCase());
  });
};
