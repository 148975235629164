import React from "react";
import { useParams } from "react-router";

import { RisksLegend } from "@/features/RisksLegend/RisksLegend";
import { useRegion } from "@/pages/RegionPage/hooks/useRegion";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { AchievementLevel } from "@/shared/AchievementLevel/ui/AchievementLevel";
import { TextBlock } from "@/shared/Achievements/ui/Achievements.styled";
import { IPopUpProps } from "@/shared/RegionPopUp/lib/types";

import { useRisksGraph } from "../hooks/useRisksGraph";
import {
  RisksBrakeByProjects,
  RisksCommonValues,
  RisksGraphContent,
  RisksValue,
  RisksWrapper,
} from "../RisksWidget.styled";

export const RisksGraph = ({ isOpenPopUp }: IPopUpProps) => {
  const { id } = useParams<"id">();
  const region = useRegion(id!);
  const { isCheckRfFoPage } = useRegions();
  const { prepareData, risksProjectItem, commonProjects, risksProjectTotal } =
    useRisksGraph();

  const getTitle = () => {
    const title = isCheckRfFoPage.isRF
      ? "РФ"
      : isCheckRfFoPage.isFO
      ? region?.attributeVals?.["SHORT_NAME"]
      : "субъекту";
    return title;
  };

  const riskDataWithoutTotal = [...prepareData]?.filter(
    ({ level }) => level !== "total"
  );

  return (
    <RisksWrapper>
      <TextBlock isOpenPopUp={isOpenPopUp} style={{ marginBottom: 0 }}>
        <div
          className="total"
          style={{
            lineHeight: "2.65rem",
            paddingBottom: 0,
            ...(isOpenPopUp && { textTransform: "uppercase" }),
          }}
        >
          Итого по {getTitle()}
        </div>
      </TextBlock>

      <RisksGraphContent>
        <AchievementLevel
          size={isOpenPopUp ? 270 : 200}
          bunchValues={prepareData}
          value={+risksProjectItem}
          color="#FFFFF"
          text="проектов в зоне риска"
          unit="%"
          radius="92%"
          isOpenPopUp={isOpenPopUp}
          blur={
            riskDataWithoutTotal.length ? "rgba(255, 255, 255, 0.5)" : undefined
          }
          blurValue={riskDataWithoutTotal.length ? 2 : undefined}
          isHighLight
        />
        <RisksCommonValues>
          <RisksBrakeByProjects>Проекты / в зоне риска</RisksBrakeByProjects>
          <RisksValue sx={{ display: "flex", marginBottom: "1.5rem" }}>
            <RisksValue color={commonProjects?.color} isBig>
              {commonProjects?.value}
            </RisksValue>
            &nbsp; / &nbsp;
            <RisksValue color={risksProjectTotal?.color} isBig>
              {risksProjectTotal?.value}
            </RisksValue>
          </RisksValue>

          <RisksBrakeByProjects>
            в том числе по уровню риска
          </RisksBrakeByProjects>
          {riskDataWithoutTotal.length
            ? [...riskDataWithoutTotal].map((projectsItem, index) => {
                return (
                  <RisksValue key={index} color={projectsItem?.color}>
                    {projectsItem?.value}{" "}
                    {index !== riskDataWithoutTotal.length - 1 && (
                      <RisksValue>/ </RisksValue>
                    )}
                  </RisksValue>
                );
              })
            : "-"}
        </RisksCommonValues>
      </RisksGraphContent>

      <RisksLegend isOpenPopUp={isOpenPopUp} />
    </RisksWrapper>
  );
};
