import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";

import { useNationalProjects } from "@/features/NationalProjectsIndicators/hooks/useNationalProjects";
import { useRegion } from "@/pages/RegionPage/hooks/useRegion";
import { Dropdown } from "@/shared/Dropdown/ui/Dropdown";
import { GeneralIndicatorsWrapper } from "@/shared/GeneralIndicators/ui/GeneralIndicators.styled";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { useRegionPopUp } from "@/shared/RegionPopUp/hooks/useRegionPopUp";
import { REGION_TABLES } from "@/shared/RegionPopUp/lib/types";
import { compareDataByProject, compareDateByFns } from "@/store/scheme/common";

import { nationalIndicatorsTitle } from "../lib/constants";
import { INationalProjectsIndicatorsProps } from "../lib/types";
import { NationalProjectsLink } from "./NationalProjects/NationalProjectsLink";
import {
  DropdownWrapper,
  HeaderButtons,
  NationalIndicatorsHeader,
  NationalProjectsIndicatorsContent,
  NationalProjectsIndicatorsPage,
  NationalProjectsIndicatorsPages,
} from "./NationalProjectsIndicators.styled";
import { getDecomposeIndicatorsData } from "./NationalProjectsItem/lib/utils";
import { NationalProjectsItem } from "./NationalProjectsItem/ui/NationalProjectsItem";

export const NationalProjectsIndicators: FC<
  INationalProjectsIndicatorsProps
> = ({ isOpenPopUp, selectedProject }) => {
  const { data, options } = useNationalProjects();
  const showRef = useRef<HTMLDivElement | null>(null);
  const { move, handleMove, handleChange } = useOpenIncident();
  const [selected, setSelected] = useState("0");
  const [page, setPage] = useState(2);
  const { id } = useParams<"id">();
  const linkAttributes = useRegion(id!)?.attributeVals;

  const codeSubject = useMemo(() => {
    const codeFk = linkAttributes?.code_fk || "0";
    return Object.keys(compareDateByFns).includes(codeFk)
      ? compareDateByFns[codeFk]
      : codeFk;
  }, [linkAttributes]);

  const decomposeIndicatorsData = getDecomposeIndicatorsData(selected);

  useEffect(() => {
    if (selectedProject) {
      setSelected(selectedProject);
    }
  }, [selectedProject, setSelected]);

  const { PopUp, PopUpButton }: any = useRegionPopUp(
    REGION_TABLES.NATIONAL_PROJECTS_INDICATORS,
    isOpenPopUp,
    nationalIndicatorsTitle,
    selected,
    undefined,
    960
  );

  const handlePageClick = (page: number) => () => {
    setPage(page);
  };

  const [projectId, projectName] = useMemo(() => {
    const nameProject = options?.find(({ value }) => value === selected)?.label;
    return [compareDataByProject[selected], nameProject];
  }, [selected]);

  const isCheckPage = useMemo(
    () => !!data?.[selected]?.["103"],
    [data?.[selected]]
  );

  const showDecomposeData = useMemo(() => {
    return [...decomposeIndicatorsData]
      ?.filter(({ id }) => (!isCheckPage ? id !== 1 : id))
      ?.filter(
        ({ indicator }) =>
          !(
            (isCheckPage && page === 1 && indicator === "102") ||
            (isCheckPage && page === 2 && indicator === "103")
          )
      );
  }, [decomposeIndicatorsData, page, isCheckPage, selected]);

  return (
    <GeneralIndicatorsWrapper
      ref={showRef}
      onMouseEnter={() => handleMove(true)}
      onMouseLeave={() => handleMove(false)}
      isOpenPopUp={isOpenPopUp}
      sx={{ minHeight: "14.7rem" }}
    >
      <NationalIndicatorsHeader isOpenPopUp={isOpenPopUp}>
        {!isOpenPopUp && <div>Показатели по Нацпроектам</div>}

        {!isOpenPopUp && move && (
          <IncidentCreateButton
            posRight={55}
            onClick={(event) => {
              handleChange(event, showRef?.current, nationalIndicatorsTitle);
            }}
          />
        )}

        <HeaderButtons isOpenPopUp={isOpenPopUp!! || !move}>
          <DropdownWrapper>
            <Dropdown
              isBigSize={isOpenPopUp}
              value={selected}
              options={options}
              onChange={setSelected}
            />
          </DropdownWrapper>

          {!isOpenPopUp && <PopUpButton />}
        </HeaderButtons>
      </NationalIndicatorsHeader>

      <NationalProjectsIndicatorsContent page={page} carusel={isCheckPage}>
        <div>
          {showDecomposeData?.map(({ id, title, selected, indicator }) => (
            <NationalProjectsItem
              isOpenPopUp={isOpenPopUp}
              key={id}
              title={title}
              selected={selected}
              indicator={indicator}
            />
          ))}
        </div>
      </NationalProjectsIndicatorsContent>
      {isCheckPage && (
        <NationalProjectsIndicatorsPages>
          <NationalProjectsIndicatorsPage
            active={page === 1}
            onClick={handlePageClick(1)}
          />
          <NationalProjectsIndicatorsPage
            active={page === 2}
            onClick={handlePageClick(2)}
          />
        </NationalProjectsIndicatorsPages>
      )}
      <NationalProjectsLink
        subject={codeSubject}
        projectId={projectId}
        projectName={projectName}
      />
      <PopUp />
    </GeneralIndicatorsWrapper>
  );
};
